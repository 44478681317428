import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';

export const useUnsubscribeByTag = () => {
  const { asPath } = useRouter();

  const {
    state: { subscribedTags },
    actions: { wsLobbyServerUnsubscribeTag },
  } = useLobbyContext();

  useEffect(() => {
    subscribedTags.forEach((tag) => {
      const pathname = asPath.split('?')[0];

      if (!tag.pages.includes(pathname)) {
        wsLobbyServerUnsubscribeTag(tag.name, tag.id);
      }
    });
  }, [asPath, subscribedTags]);
};
