import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { authActions } from '@store/storeshed';

export const useToken = () => {
  const router = useRouter();
  // const { search } = useLocation();

  useEffect(() => {
    // const params = new URLSearchParams(search);

    if (router.query.t) {
      const t = router.query.t as string;
      authActions.setToken(t);

      // params.delete('t');
      router.replace({
        query: { ...router.query, t: undefined },
      });
    }

    if (router.query.locale) {
      // params.delete('locale');
      router.replace({
        // search: params.toString(),
        query: { ...router.query, locale: undefined },
      });
    }
  }, []);
};
